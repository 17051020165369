////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
import autosize from 'autosize';
//import bytesToSize from 'bytesToSize';
////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

//console.log('hola');

$(document).ready(function ($) {

    POLP_boot.iePolyfillImgAspectRatio();
    POLP_boot.scrollToAnchor();
    //POLP_boot.dsg_formFileInput_dragAndDrop();

    ////////////////////////////////////////////////////////////////////////////
    // Boostrap init

    //$('[data-toggle="tooltip"]').tooltip();
    //$('[data-toggle="tooltip"] , [data-toggle="tab"]').tooltip({
    $('[data-toggle="tooltip"]').tooltip({
        //trigger: 'hover',
        //placement: 'right',
        //animation: false,
        //delay: {"show": 100, "hide": 0},
        //container: 'body',
        //cssclass: $('[data-toggle="tooltip"]').attr('data-cssclass') //'tooltip-primary',
    });
    //console.log($('[data-toggle="tooltip"]'));

    ////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////

    // Bootstrap Carousel

    $('.carousel').carousel({
        interval: 0,
        //pause: false,
    });

    // thirdparty Bootstrap Touch events
    $('.carousel').bcSwipe({threshold: 50});

    ////////////////////////////////////////////////////////////////////////////
    // thirdparty

    // autosize
    // http://www.jacklmoore.com/autosize/
    // https://github.com/jackmoore/autosize
    autosize(document.querySelectorAll('textarea'));

    ////////////////////////////////////////////////////////////////////////////
    //

});


$(window).on('load', function () {
    //
    POLP_boot.domFade();

}); // fin jQuery(window).load
