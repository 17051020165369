////////////////////////////////////////////
// funciones necesarias para la página tanto en maquetacion estatica como
// en la definitiva

(function (POLP_cli, $) {

  POLP_cli.initPhotoSwipe = function (el,items) {
    // https://www.liwen.id.au/photoswipe/
    // get properties from child a/img/figcaption elements,
    var $figure = el; //$(this);
    //var $a = $figure.find('a');
    var $a = $figure;
    var $src = $a.attr('href');
    var $title = $figure.find('figcaption').html();
    $msrc = $figure.find('img').attr('src');
    // if data-size on <a> tag is set, read it and create an item
    if ($a.data('size')) {
      var $size = $a.data('size').split('x');
      var item = {
        src : $src,
        w : $size[0],
        h : $size[1],
        title : $title,
        msrc : $msrc
      };
      // if not, set temp default size then load the image to check actual size
    } else {
      var item = {
        src : $src,
        w : 800, // temp default size
        h : 600, // temp default size
        title   : $title,
        msrc  : $msrc
      };
      // load the image to check its dimensions
      // update the item as soon as w and h are known (check every 30ms)
      var img = new Image();
      img.src = $src;
      var wait = setInterval(function() {
        var w = img.naturalWidth;
        var h = img.naturalHeight;
        if (w && h) {
          clearInterval(wait);
          item.w = w;
          item.h = h;
        }
      }, 30);
    }
    // Save the index of this image then add it to the array
    var index = items.length;
    items.push(item);
    // Event handler for click on a figure
    $figure.on('click', function(event) {
      event.preventDefault(); // prevent the normal behaviour i.e. load the <a> hyperlink
      // Get the PSWP element and initialise it with the desired options
      var $pswp = $('.pswp')[0];
      var options = {
        index: index,
        bgOpacity: 0.95,
        showHideOpacity: true,
        shareEl: false,
      }
      new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options).init();
    });
  };//cli photoswipe

}(window.POLP_cli = window.POLP_cli || {}, jQuery, undefined));


////////////////////////////////////////////////////////////////////////////////
// ready
////////////////////////////////////////////////////////////////////////////////
// $(document).ready(function ($) {
//
//   ////////////////////////////////////////////////////////////////////////////
//   // carousel header
//   ////////////////////////////////////////////////////////////////////////////
//
//   var carouselHeader = $('#carouselHeader');
//   if (carouselHeader.length > 0) {
//     carouselHeader.carousel({
//       interval: 5000,
//       //ride: true,
//     });
//   }
//   // Thanks to Maaaaark - https://github.com/maaaaark/bcSwipe/blob/master/jquery.bcSwipe.min.js
//   !function (t) {
//     t.fn.bcSwipe = function (e) {
//       var n = {threshold: 50};
//       return e && t.extend(n, e), this.each(function () {
//         function e(t) {
//           1 == t.touches.length && (u = t.touches[0].pageX, c = !0, this.addEventListener("touchmove", o, !1))
//         }
//         function o(e) {
//           if (c) {
//             var o = e.touches[0].pageX, i = u - o;
//             Math.abs(i) >= n.threshold && (h(), t(this).carousel(i > 0 ? "next" : "prev"))
//           }
//         }
//         function h() {
//           this.removeEventListener("touchmove", o), u = null, c = !1
//         }
//         var u, c = !1;
//         "ontouchstart"in document.documentElement && this.addEventListener("touchstart", e, !1)
//       }), this
//     }
//   }(jQuery);
//
//   // Swipe functions for Bootstrap Carousel
//   carouselHeader.bcSwipe({threshold: 50});
//
//
// }); // fin jQuery(document).ready


////////////////////////////////////////////////////////////////////////////////
// load
////////////////////////////////////////////////////////////////////////////////

// $(window).on('load', function () {
//
//   ///////////////////////////////////////////////////////////////////////////
//   // para el sticky header
//   ///////////////////////////////////////////////////////////////////////////
//   var nav = $('#sticky-navHeader');
//   var heightOffset = $('#navHeader-top').outerHeight() + nav.outerHeight();
//   $('#sticky-offset').css({
//     'height': heightOffset,
//   });
//   $(window).scroll(function () {
//     if ($(this).scrollTop() > (heightOffset - nav.outerHeight())) {
//       nav.addClass("sticky");
//     } else {
//       nav.removeClass("sticky");
//     }
//   });
//   $(window).resize(function () {
//     $('#sticky-offset').removeAttr("style");
//     var heightOffset = $('#navHeader-top').outerHeight() + nav.outerHeight();
//     $('#sticky-offset').css({
//       'height': heightOffset,
//     });
//   });
//
// }); // fin jQuery(window).load
